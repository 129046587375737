<template>
  <div>
    <div id="search-page" class="flex-column">
      <h1 class="h1-title">Appraisals</h1>

      <search-bar
        class="margin-right margin-top-2"
        :placeholder="
          isDealership
            ? 'Search for car registration, VIN, make, model or customer name'
            : 'Search for car registration, make, model or dealership name'
        "
        :availableFilterMode="isDealership ? 'dealership' : 'reseller'"
      >
        <div v-if="currentDealer" class="flex-row">
          <secondary-button
            class="min-width-120 margin-left-05 vertical-flex-center"
            :title="downloading ? 'Downloading...' : 'Download'"
            icon="/assets/img/icon-download.svg"
            v-on:click="download()"
          />
          <secondary-button
            class="min-width-120 margin-left-05 vertical-flex-center"
            title="Request PPSR"
            v-on:click="showPPSRModal()"
          />
          <secondary-button
            class="min-width-120 margin-left-05 vertical-flex-center"
            v-if="currentDealer.is_manager"
            title="Archive All Active"
            v-on:click="showArchiveAllModal()"
          />
          <secondary-button
            class="min-width-120 margin-left-05 vertical-flex-center"
            v-if="currentDealership.is_tender_account && currentDealer.is_manager"
            title="Tender Pending"
            v-on:click="showTenderModal()"
          />
          <secondary-button
            class="min-width-120 margin-left-05 vertical-flex-center"
            v-if="
              (currentDealer && currentDealer.has_web_form_creation_access) ||
              currentDealership.has_web_form_creation_access
            "
            title="Create New Appraisal"
            v-on:click="showCreateForm()"
          />
          <div class="position-relative">
            <secondary-button
              class="min-width-120 margin-left-05 vertical-flex-center"
              v-if="
                (currentDealership.is_tender_account || currentDealership.is_buyers_account) &&
                currentDealer.is_manager &&
                (currentDealer.has_web_form_creation_access || currentDealership.has_web_form_creation_access)
              "
              title="Bulk Create New Appraisal"
              v-on:click="newFormListDropdownVisible = !newFormListDropdownVisible"
              :selected="newFormListDropdownVisible"
            />
            <div class="iappraise-dropdown margin-left-05" v-if="newFormListDropdownVisible">
              <div class="iappraise-dropdown-button" v-on:click="showBulkCreateForm('rego')">Rego</div>
              <div class="iappraise-dropdown-button" v-on:click="showBulkCreateForm('vin')">VIN</div>
            </div>
          </div>
          <div class="position-relative">
            <secondary-button
              class="min-width-120 margin-left-05 vertical-flex-center"
              v-if="currentDealer"
              title="Save Report"
              v-on:click="showSaveReport()"
              :disabled="!hasNonDateFilters()"
              :selected="showReportName"
            />
            <div class="dropdown shadow padding-1-05 min-width-320" v-if="showReportName">
              <div class="flex-row">
                <h4 class="h4 margin-right-auto vertical-flex-center">Report Name</h4>
                <button class="no-button-style" v-on:click="showReportName = false">
                  <img src="/assets/img/icon-close.svg" />
                </button>
              </div>
              <form @submit.prevent="saveReport()" class="flex-column">
                <input v-model="reportName" type="text" required class="text-field margin-top" placeholder="Name" />
                <div class="flex-row">
                  <primary-button title="Apply" class="margin-top margin-left-auto min-width-100" type="submit" />
                </div>
              </form>
            </div>
          </div>
          <secondary-button
            class="min-width-120 margin-left-05"
            v-if="currentDealer.is_manager"
            title="Transfer"
            :disabled="selectedForms.length <= 0"
            v-on:click="showSelectAppraisalsForTransferModal()"
          />
          <secondary-button
            class="min-width-120 margin-left-05"
            v-if="currentDealer.is_manager"
            title="Fleet PDF"
            :disabled="selectedForms.length <= 0"
            v-on:click="openFleetPDFs()"
          />
          <secondary-button
            class="min-width-120 margin-left-05"
            v-if="
              (currentDealer && currentDealership.is_marketing_account && currentDealer.is_manager) ||
              currentDealer.has_marketing_access
            "
            title="Market"
            :disabled="selectedForms.length <= 0"
            v-on:click="showSelectAppraisalsForMarketingModal()"
          />
        </div>
      </search-bar>

      <filter-bar />

      <div class="results-table-container">
        <results-table
          class="margin-top"
          :checkBox="true"
          :availableSearchMode="currentDealership ? 'dealership' : 'reseller'"
          @formChecked="formChecked"
          @loadedMoreForms="loadedMoreForms"
          ref="resultsTable"
        />
      </div>

      <ppsr-modal v-if="ppsrModalVisible" @cancel="modalCancelled" />
      <archive-all-pending-modal v-if="archiveAllModalVisible" @cancel="modalCancelled" @submit="archiveAllSubmitted" />
      <select-dealership-modal
        v-if="transferModalVisible"
        :forms="selectedForms"
        @cancel="modalCancelled"
        @submit="transferSelectedForms"
      />
      <marketing-list-modal v-if="marketModalVisible" @cancel="modalCancelled" @submit="marketForms" />
      <create-tender-modal v-if="tenderModalVisible" @cancel="modalCancelled" @submit="tenderSelectedForms" />
      <new-form-modal v-if="newFormModalVisible" @cancel="modalCancelled" @formCreated="newFormCreated" />
      <create-appraisals-from-rego-list-modal
        v-if="newFormListModalVisible"
        :uploadMode="newFormListModalVisible"
        @formCreated="newBulkFormCreated"
        @cancel="modalCancelled"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { transferForms, archiveAllForms } from "../../api/Form.js";
import { addMarketingPlanMessages } from "../../api/Marketing.js";
import { endpoints } from "../../api/axiosHandler";

import ResultsTable from "../../components/Views/v2/ResultsTable.vue";
import SearchBar from "../../components/Views/v2/SearchBar.vue";
import SecondaryButton from "../../components/Buttons/v2/SecondaryButton.vue";
import PrimaryButton from "../../components/Buttons/v2/PrimaryButton.vue";
import FilterBar from "../../components/Views/v2/FilterBar.vue";
import PpsrModal from "../../components/Modals/v2/PpsrModal.vue";
import ArchiveAllPendingModal from "../../components/Modals/v2/ArchiveAllPendingModal.vue";
import NewFormModal from "../../components/Modals/v2/NewFormModal.vue";
import CreateTenderModal from "../../components/Modals/v2/CreateTenderModal.vue";
import CreateAppraisalsFromRegoListModal from "../../components/Modals/v2/CreateAppraisalsFromRegoListModal.vue";
import SelectDealershipModal from "../../components/Modals/v2/TransferAppraisalModal.vue";
import MarketingListModal from "../../components/Modals/v2/MarketingListModal.vue";

export default {
  name: "Search",
  components: {
    ResultsTable,
    PpsrModal,
    ArchiveAllPendingModal,
    SelectDealershipModal,
    CreateTenderModal,
    CreateAppraisalsFromRegoListModal,
    NewFormModal,
    MarketingListModal,

    SearchBar,
    SecondaryButton,
    PrimaryButton,
    FilterBar
  },
  data: function () {
    return {
      ppsrModalVisible: false,
      archiveAllModalVisible: false,
      transferModalVisible: false,
      tenderModalVisible: false,
      newFormModalVisible: false,
      newFormListModalVisible: false,
      newFormListDropdownVisible: false,
      marketModalVisible: false,
      selectedForms: [],
      requestStatus: null,
      requestLoading: false,
      requestMessage: "",
      timer: null,
      showReportName: false,
      reportName: "",

      downloading: false
    };
  },
  methods: {
    showPPSRModal: function () {
      this.ppsrModalVisible = true;
      this.requestLoading = false;
      this.requestStatus = null;
      this.requestMessage = "";
    },
    showArchiveAllModal: function () {
      this.archiveAllModalVisible = true;
    },
    showSelectAppraisalsForTransfer: function () {
      this.selectedForms = [];
    },
    showSelectAppraisalsForMarketing: function () {
      this.selectedForms = [];
    },
    showSelectAppraisalsForMarketingModal: function () {
      this.marketModalVisible = true;
    },
    showSelectAppraisalsForTransferModal: function () {
      this.transferModalVisible = true;
    },
    showTenderModal: function () {
      this.tenderModalVisible = true;
    },
    showCreateForm: function () {
      this.newFormModalVisible = true;
    },
    showBulkCreateForm: function (mode) {
      this.newFormListDropdownVisible = false;
      this.newFormListModalVisible = mode;
    },
    modalCancelled: function () {
      this.ppsrModalVisible = false;
      this.archiveAllModalVisible = false;
      this.transferModalVisible = false;
      this.tenderModalVisible = false;
      this.marketModalVisible = false;
      this.newFormModalVisible = false;
      this.newFormListModalVisible = "";
      this.showReportName = false;
    },
    archiveAllSubmitted: function (dealershipID) {
      archiveAllForms(dealershipID)
        .then(() => {
          this.storedForms().forEach(form => {
            if (form.appraisal_status == "Active") {
              form.appraisal_status = "Complete";
            }
          });
          this.modalCancelled();
        })
        .catch(error => {
          this.modalCancelled();
          this.addError(error);
        });
    },
    tenderSelectedForms: function (timeDict) {
      this.startTender(timeDict);
      this.modalCancelled();
    },
    newFormCreated: function (form) {
      this.modalCancelled();
      this.addForms([form]);
      this.$router.push({
        name: "Form",
        params: { id: form.id }
      });
    },
    newBulkFormCreated: function (form) {
      this.addForms([form]);
    },
    transferSelectedForms: function (dealershipID) {
      transferForms(
        this.selectedForms.map(form => form.url),
        endpoints.dealership + dealershipID + "/"
      )
        .then(response => {
          this.addForms(response);
        })
        .catch(error => {
          this.addError(error);
        });
      this.selectedForms = [];
      this.modalCancelled();
    },
    openFleetPDFs: function () {
      this.selectedForms.forEach(form => {
        let routeData = this.$router.resolve({
          name: "FleetPDF",
          params: {
            id: form.id,
            noprint: this.selectedForms.length > 1 ? "true" : ""
          }
        });
        window.open(routeData.href, "_blank");
      });
    },
    marketForms: function (marketPlanId) {
      addMarketingPlanMessages(
        marketPlanId,
        this.selectedForms.map(form => form.url)
      )
        .then(() => {
          this.selectedForms = [];
          this.modalCancelled();
        })
        .catch(error => {
          this.addError(error);
        });
    },
    formChecked: function (selectedForms) {
      this.selectedForms = selectedForms;
    },
    download: function () {
      if (this.downloading) {
        return;
      }
      this.downloading = true;
      this.downloadCSV(endpoints.form + "download-csv/").then(() => {
        this.downloading = false;
      });
    },
    hasNonDateFilters: function () {
      for (const [key] of Object.entries(this.getFilters)) {
        if (key != "date_time_created_gt" && key != "date_time_created_lt" && key != "quick_search") {
          return true;
        }
      }
      return false;
    },
    showSaveReport: function () {
      if (!this.hasNonDateFilters()) {
        return;
      }
      this.showReportName = !this.showReportName;
    },
    saveReport: function () {
      this.modalCancelled();
      this.createReportFilter(this.reportName);
    },
    refreshForms: function () {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        if (this.page == 1) {
          this.getForms({});
        }
      }, 15 * 60 * 1000);
    },
    loadedMoreForms: function () {
      this.refreshForms();
    },
    ...mapActions({
      setFormsUrl: "formStore/setCurrentUrl",
      getForms: "formStore/getForms",
      addForms: "formStore/addForms",
      startTender: "formStore/startTender",
      cancelRequest: "formStore/cancelRequest",
      downloadCSV: "formStore/downloadCSV",
      createReportFilter: "formStore/createReportFilter",
      addError: "errorStore/addError"
    })
  },
  computed: {
    ...mapState({
      currentDealer: state => state.dealershipStore.currentDealer,
      currentDealership: state => state.dealershipStore.currentDealership,
      currentReseller: state => state.resellerStore.currentReseller
    }),
    ...mapGetters({
      storedForms: "formStore/getForms",
      isDealership: "authStore/isDealership",
      getFilters: "formStore/getFilters"
    })
  },
  mounted: function () {
    let app = this;
    this.setFormsUrl(endpoints.form);
    this.$nextTick(() => {
      app.getForms({});
      app.refreshForms();
    });
  },
  beforeUnmount() {
    clearInterval(this.timer);
    this.cancelRequest();
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

#search-page {
  padding-top: $PADDING_X2_05;
  padding-left: $PADDING_X4;
}
</style>
